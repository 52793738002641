import React from "react";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import {
  SrLPHero,
  SrLPWhiteGlove,
  SrLPLargeFeatures,
  SrLPCTA,
  SrLPTestimonials,
} from "../../../data/landing/ordering";
import Hero from "../../../components/Hero/hero";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import Badges from "../../../components/ConversionPath/Badges";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
import ogImage from "../../../images/global_assets/og-image.png";

const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const Badges = loadable(() =>
  import("../../../components/ConversionPath/Badges")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));

const whiteGloveImport = "about-us-white-glove.png";

const OrderingLP = () => {
  return (
    <LpLayout phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="SpotOn Order with Google Demo | Point-of-Sale"
        description="Boost efficiency, increase sales, and keep guests coming back with a single point-of-sale solution for in-person orders, Order with Google, and online ordering"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />

      <Hero sectionData={SrLPHero} circleBg={false} />

      <Badges />

      <section style={{ marginTop: "60px" }}>
        <TestmonialReviews sectionData={SrLPTestimonials} />
      </section>

      <PartnerLogos />

      <LargeFeatures
        sectionData={SrLPLargeFeatures}
        style={{ marginTop: "60px" }}
      />

      <WhiteGlove
        sectionData={SrLPWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />

      <LargeCta sectionData={SrLPCTA} />
    </LpLayout>
  );
};

export default OrderingLP;
